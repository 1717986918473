<template>
<div style="max-width: 800px">

  <div class="card-box-full">
    <div class="card-box-header">
      <table width="100%">
        <tr>
          <td>
            Top Senders <span style="margin-left: 6px; font-size: 10px; font-weight: 600">last 24 hours</span>
          </td>
          <td align="right">
            <div v-if="isDownloadingRadars">
              <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
            <div v-else-if="errorMessage == null">
              <CButton color="primary" style="margin: -10px 0px -10px 0px; text-transform: capitalize;" @click="clickedRadars()">
                <table>
                  <tr>
                    <td>
                      {{ nameForRadar(currentRadar) }}
                    </td>
                    <td>
                      <div style="font-size: 30px; margin: -15px -5px -10px 0px">&#9662;</div>
                    </td>
                  </tr>
                </table>
              </CButton>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!-- ----------------------------------------------------------------- -->
    <div v-if="errorMessage">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
    </div>

    <div v-if="isDownloadingSenders || isDownloadingProfiles">
      <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 10px">
        <span class="sr-only"></span>
      </div>
    </div>

    <div v-else>
      <table style="margin-top: 10px; margin-bottom: 0px;">
        <tr v-for="result in results">
          <td>

            <!-- -------------------------------------- -->
            <div style="width: 90px; height: 70px; text-align: center; vertical-align: top; position: relative; display: inline-block; margin-top: 10px">
              <div v-if="profiles[result.sender]">
                <div v-if="profiles[result.sender].last_reason == 'domain_reputation'">
                  <span class="badge badge-pill badge-light">Domain</span>
                </div>
                <div v-else-if="profiles[result.sender].last_reason == 'fingerprint_override'">
                  <span class="badge badge-pill badge-light">Fingerprint</span>
                </div>
                <div v-else-if="profiles[result.sender].last_reason == 'ml_model'">
                  <span class="badge badge-pill badge-light">ML Model</span>
                </div>
                <div v-else-if="profiles[result.sender].last_reason == 'sender_override'">
                  <span class="badge badge-pill badge-light">Sender</span>
                </div>
                <div v-else>
                  <span class="badge badge-pill badge-light">{{ profiles[result.sender].last_reason }}</span>
                </div>
              </div>

              <div style="margin-top: 5px">
                <span class="badge badge-pill badge-secondary">{{ result.amount.toLocaleString() }}</span>
              </div>
            </div>

            <!-- -------------------------------------- -->
            <div v-if="profiles[result.sender]" style="width: 650px; height: 70px; position: relative; display: inline-block; padding-top: 8px; cursor: pointer" @click="openSenderProfile(result.sender)">



              <div v-if="profiles[result.sender].last_message_blocked" class="alert alert-danger" style="padding: 5px">
                <table width="100%">
                  <tr>
                    <td>
                      <span class="badge badge-pill badge-danger">
                        {{ profiles[result.sender].sender }}
                      </span>
                      <span style="font-size: 12px; ">
                        <span style="margin-left: 5px">last seen </span>
                        <timeago :datetime="profiles[result.sender].last_seen"></timeago>
                      </span>
                    </td>
                  </tr>
                </table>
                <div>
                  <div v-if="showSenderMessages">
                    {{ truncateMessage(profiles[result.sender].last_message) }}
                  </div>
                  <div v-else>
                    <div class="clickable-text" style="color: #3A9CFC" @click.stop="showSenderMessages = true">Show the most recent message from this sender</div>
                  </div>
                </div>
              </div>

              <div v-else class="alert alert-success" style="padding: 5px">
                <table width="100%">
                  <tr>
                    <td>
                      <span class="badge badge-pill badge-success">
                        {{ profiles[result.sender].sender }}
                      </span>
                      <span style="font-size: 12px; ">
                        <span style="margin-left: 5px">last seen </span>
                        <timeago :datetime="profiles[result.sender].last_seen"></timeago>
                      </span>
                    </td>
                  </tr>
                </table>
                <div>
                  <div v-if="showSenderMessages">
                    {{ truncateMessage(profiles[result.sender].last_message) }}
                  </div>
                  <div v-else>
                    <div class="clickable-text" style="color: #3A9CFC" @click.stop="showSenderMessages = true">Show the most recent message from this sender</div>
                  </div>
                </div>
              </div>



            </div>
            <div v-else style="width: 650px; height: 70px; position: relative; display: inline-block; padding-top: 8px">
              <div class="alert alert-secondary" style="padding: 5px; height: 55px">
                <span class="badge badge-pill badge-dark">Profile not found</span><br>
                {{ result.sender }}
              </div>
            </div>

            <div style="width: 100%; height: 1px; background: #c4c9d0"></div>

          </td>
        </tr>
      </table>
    </div>

  </div>

  <CModal title="Select A Radar" :show.sync="displayRadars">
    <table style="text-transform: capitalize" width="100%">
      <tr>
        <td v-for="category in radarCategories()" align="center" valign="top" width="50%">
          <h4>{{ category }}</h4>
          <div v-for="radar in radarsForCategory(category)">
            <a href="#" @click.prevent="selectedRadar(radar)">{{ radar.displayName }}</a>
          </div>
        </td>
      </tr>
    </table>
    <template #footer-wrapper><span></span></template>
  </CModal>

</div>
</template>

<script>
export default {
  name: 'Senders',
  data() {
    return {
      currentRadar: this.defaultRadar(),
      displayRadars: false,
      errorMessage: null,
      isDownloadingProfiles: false,
      isDownloadingRadars: false,
      isDownloadingSenders: false,
      languageNames: null,
      profiles: {},
      radars: new Map(),
      regionNames: null,
      results: [],
      showSenderMessages: false
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedRadars() {
      this.displayRadars = true;
    },
    //--------------------------------------------------------------------------
    defaultRadar() {
      return {
        id: 'sender.country.global',
        group: 'sender',
        category: 'country',
        tag: 'global'
      }
    },
    //--------------------------------------------------------------------------
    downloadRadars() {
      if (this.isDownloadingRadars) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingRadars = true;
      }

      // Fetch the radars
      var that = this;
      var method = "GET";
      var url = "https://sms-radar.platform.robocalls.ai/radars";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingRadars = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processRadars(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingRadars = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingRadars = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    downloadProfiles(senders) {

      if (this.isDownloadingProfiles) {
        return;
      } else {
        this.profiles = {};
        this.errorMessage = null;
        this.isDownloadingProfiles = true;
      }

      var body = JSON.stringify({
        senders: senders
      });

      var that = this;
      var method = "POST";
      var url = "https://sms-profile.platform.robocalls.ai/sender/profiles";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingProfiles = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processProfiles(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingProfiles = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingProfiles = false;
          that.errorMessage = error;
        }
      })

    },
    //--------------------------------------------------------------------------
    downloadSenders(radar) {
      if (this.isDownloadingSenders) {
        return;
      } else {
        this.errorMessage = null;
        this.isDownloadingSenders = true;
        this.results = [];
      }

      // Fetch the senders
      var that = this;
      var method = "GET";
      var url = "https://sms-radar.platform.robocalls.ai/top/24-hours?id=" + encodeURIComponent(radar.id);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isDownloadingSenders = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the response
            try {
              var result = JSON.parse(body);
              that.processSenders(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isDownloadingSenders = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isDownloadingSenders = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
    //--------------------------------------------------------------------------
    nameForCountry(country) {
      try {
        return this.regionNames.of(country.toUpperCase());
      } catch (error) {
        return country;
      }
    },
    //--------------------------------------------------------------------------
    nameForLanguage(language) {
      switch (country.toUpperCase()) {
        case "HK":
          return "Hong Kong SAR China";
        default:
        try {
          return this.regionNames.of(country.toUpperCase());
        } catch (error) {
          return country;
        }
      }
    },
    //--------------------------------------------------------------------------
    nameForRadar(radar) {
      var name = radar.category + ": ";
      if (radar.category == "country") {
        name += this.nameForCountry(radar.tag);
      } else if (radar.category == "language") {
        name += this.nameForLanguage(radar.tag);
      } else {
        name += radar.category;
      }
      return name;
    },
    //--------------------------------------------------------------------------
    openSenderProfile(sender) {
      var url = "http://admin.robocalls.ai/#/sms-profile/sender/" + sender;
      window.open(url, '_blank');
    },
    //--------------------------------------------------------------------------
    processProfiles(response) {
      // Make sure we received results
      if (!response || !response.profiles) {
        this.errorMessage = "No profiles were returned";
        return;
      }

      var profiles = {};
      for (var x = 0; x < response.profiles.length; x++) {
        profiles[response.profiles[x].sender] = response.profiles[x];
      }
      this.profiles = profiles;
    },
    //--------------------------------------------------------------------------
    processRadars(response) {
      // Make sure we received radars
      if (!response || !response.radars) {
        this.errorMessage = "No radars were returned";
        return;
      }

      for (var x = 0; x < response.radars.length; x++) {
        var radar = response.radars[x];
        if (radar.group.toLowerCase() != "sender") {
          continue;
        }
        if (!this.radars.has(radar.category)) {
          this.radars.set(radar.category, [])
        }

        // Add the display name
        if (radar.category == "country") {
          radar.displayName = this.nameForCountry(radar.tag);
        } else if (radar.category == "language") {
          radar.displayName = this.nameForLanguage(radar.tag);
        } else {
          radar.displayName = radar.category;
        }

        this.radars.get(radar.category).push(radar);
      }

    },
    //--------------------------------------------------------------------------
    processSenders(response) {
      // Make sure we received results
      if (!response || !response.results) {
        this.errorMessage = "No results were returned";
        return;
      }

      var results = [];
      var senders = [];
      for (const sender in response.results) {
        results.push({
          sender: sender,
          amount: response.results[sender]
        });
        senders.push(sender);
      }

      // Sort the results
      results.sort((a, b) => {
        if (a.amount < b.amount) {
          return 1;
        } else {
          return -1;
        }
      });
      this.results = results;

      // Download the profiles for each sender
      this.downloadProfiles(senders);
    },
    //--------------------------------------------------------------------------
    radarCategories() {
      var categories = [];
      this.radars.forEach(function(value, key) {
        categories.push(key);
      });
      return categories;
    },
    //--------------------------------------------------------------------------
    radarsForCategory(category) {
      if (!this.radars.has(category)) {
        return [];
      } else {
        return this.radars.get(category).sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          } else {
            return 1;
          }
        });
      }
    },
    //--------------------------------------------------------------------------
    selectedRadar(radar) {
      this.currentRadar = radar;
      this.displayRadars = false;
      this.downloadSenders(radar);
    },
    //--------------------------------------------------------------------------
    truncateMessage(message) {
      if (message == null) {
        return "";
      }
      if (message.length > 85) {
        message = message.substring(0, 85) + "...";
      }
      return message;
    }
  },
  mounted() {
    this.regionNames = new Intl.DisplayNames(['en'], {
      type: 'region'
    });
    this.languageNames = new Intl.DisplayNames(['en'], {
      type: 'language'
    });
    this.downloadRadars();
    this.downloadSenders(this.currentRadar);
  }
}
</script>
